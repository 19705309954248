import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { projects } from '../../../utils/projects'
import ProjectPreview from './ProjectPreview'

export default class Projects extends Component {
    render() {
        return (
            <div className="wrapper">
                <h1>Projects</h1>
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            { projects.map((project, i) => {
                                return (
                                    <ProjectPreview key={i} index={i} project={project} />
                                )
                            })}
                        </Col>
                    </Row>
                    
                </Grid>
               
                
            </div>
        )
    }
}


