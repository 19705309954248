import React, { Component } from 'react'
import Faq from 'react-faq-component';

const data = {
    rows: [
      {
        title: "WHAT ARE YOUR BUSINESS HOURS?",
        content: `Our business hours are Monday through Friday, 9:00am - 5:00pm. However, each client's schedule and needs vary, 
        therefore we also will be happy to schedule appointments with you at times that are a better schedule fit. We strive to offer schedule convenience and flexibility.`
      },
      {
        title: "WHAT KIND OF INTERIOR STYLE DOES INTERIOR MOTIVES DO BEST?",
        content: `Interior Motives does not specialize in any single interior design style. Our mission is to create a style that is interpretive of the client's personal expression. 
        From contemporary to eclectic, transitional to traditional, we have assisted clients in transforming their interiors into something well-planned and exquisitely detailed, all to meet the client's personal desires.`
      },
      {
        title: "DO I RECEIVE ANY DISCOUNTS ON PRODUCTS THAT I PURCHASE THROUGH INTERIOR MOTIVES?",
        content: `Working with an interior designer with access to "trade only" sources yields savings. Often this offsets designer fees, making your project more affordable.`
      },
      {
        title: `WHAT IS THE PRICING STRUCTURE?`,
        content: `We work on an hourly fee with a mark up on the purchases made through Interior Motives. 

        The hourly fee is a flat hourly rate, charged while the designer works with the client on the varied services and project requirements. The mark up is an additional percentage over the cost, 
        when the client makes purchases assisted by Interior Motives, and is charged the “trade discounted” price of a product.

        During initial consultations, Michele Townley will spend an hour getting to know what the client is looking for in their home and discuss her ideas about how she feels 
        she can best meet the design requirements while keeping in mind the clients needs as well as creating a beautiful design concept. `
      },
      {
        title: `WHAT SIZE PROJECTS DOES INTERIOR MOTIVES ACCEPT? CAN I START WITH ONE ROOM?`,
        content: `We gladly accommodate all budgetary concerns and want to make good interior design affordable. We will show you how to make the most impactful changes to your interior while staying within your budget.

        Interior Motives has completed work for clients that have one room projects, compete remodels or new construction of homes. We look forward to building a relationship with our clients regardless of the 
        scale of the project. `
      },
      {
        title: `WHAT DO I NEED TO KNOW ABOUT YOUR INITIAL CONSULTATION?`,
        content: `Our Initial Consultations are scheduled by appointment. The fee for this consultation is $175 and should be scheduled for a one hour commitment. 
        Commonly, a color scheme or palette is established or begun. This initial meeting is also the best time to share photos or magazine pictures of rooms, homes, 
        furniture, colors and styles of interiors that you like. This process helps the clients, who really do not know how to explain what they like, and helps build the foundation for the project. `
      },
      {
        title: `DOES INTERIOR MOTIVES SERVICE INTERIORS OUTSIDE OF THE LOS ANGELES AREA?`,
        content: `Interior Motives serves clients across the United States. When working remotely, the client will incur travel expenses. In working out of another area, 
        we utilize our industry expertise to assist the client in locating as many local suppliers and craftspeople to keep shipping and travel to a minimum.`
      },
      {
        title: `WHY IS IT SO IMPORTANT FOR A HOME OWNER PREPARING TO DO A REMODEL TO HAVE A CONTRACTOR AS WELL AS A DESIGNER?`,
        content: `More times than not, the contractor is experienced in completing the actual work on the project, but does not really 
        get involved in the design of the project. There are many details that can be missed if the project does not have a designer working on it; and 
        it’s these details that will make or break the outcome of the remodel. Also, having a harmonious team working together can be the biggest blessing on 
        the project with the assurance of a project being completed on time and in a professional manner. I have many contractors and sub-contractors that I refer 
        to my clients and pride myself in working with the best in the industry. `
      }
    ]
  }

export default class FAQ extends Component {
    render() {
        return (
            <div className="wrapper">
                <h1>FAQ</h1>
                    <Faq 
                        data={data}
                        styles={{
                            transitionDuration: "0.5s",
                            timingFunc: "linear"
                        }}
                        config={{
                            tabFocus: true,
                        }}
                    />
                
            </div>
        )
    }
}
