export const projects = [
    {
        name: "Bubman",
        location: "Encino, California",
        date: "2019-2020",
        shortBody: `Ground up custom new build. 3500 sq ft 2 story house.`,
        longBody: `Took a single story home down to the foundation. Added
        more square footage and added a second story. Basically we built a new home.
        Situated on a gorgeous view lot of the San Fernando Valley, adding a second
        story created a master suite and deck to take in the views. Clean, contemporary
        and fresh, this interior features many custom features like lighting, stone and a
        staircase that looks as if it's floating.`,
        photos: [
            require("../assets/images/projects/bubman/2.jpg"),
            require("../assets/images/projects/bubman/1.jpg"),
            require("../assets/images/projects/bubman/3.jpg"), 
            require("../assets/images/projects/bubman/4.jpg"), 
            require("../assets/images/projects/bubman/5.jpg"), 
            require("../assets/images/projects/bubman/6.jpg"), 
            require("../assets/images/projects/bubman/7.jpg"), 
            require("../assets/images/projects/bubman/8.jpg"), 
            require("../assets/images/projects/bubman/9.jpg"), 
            require("../assets/images/projects/bubman/10.jpg"), 
            require("../assets/images/projects/bubman/11.jpg"), 
            require("../assets/images/projects/bubman/12.jpg"), 
            require("../assets/images/projects/bubman/13.jpg"), 
            require("../assets/images/projects/bubman/14.jpg"), 
            require("../assets/images/projects/bubman/15.jpg"), 
            require("../assets/images/projects/bubman/16.jpg"), 
            require("../assets/images/projects/bubman/17.jpg")
        ]
    },
    {
        name: "Rose",
        location: "Cheviot Hills, California",
        date: "2019",
        shortBody: `Full remodel and addition. 4800 sq ft 2 story house.`,
        longBody: `Gorgeous traditional home taken down to the studs to create a new interior floor plan so the clients could enjoy all the home had to offer, it just needed a new flow. 
        From custom wood chevron entry floors to an expansive kitchen and great room to the newly designed media room. This home has it all now.`,
        photos: [
            require("../assets/images/projects/cheviotHills/3.jpg"), 
            require("../assets/images/projects/cheviotHills/1.jpg"),
            require("../assets/images/projects/cheviotHills/2.jpg"),
            require("../assets/images/projects/cheviotHills/4.jpg"),
            require("../assets/images/projects/cheviotHills/5.jpg"), 
            require("../assets/images/projects/cheviotHills/6.jpg"), 
            require("../assets/images/projects/cheviotHills/7.jpg"), 
            require("../assets/images/projects/cheviotHills/8.jpg"), 
            require("../assets/images/projects/cheviotHills/9.jpg"), 
            require("../assets/images/projects/cheviotHills/10.jpg"), 
            require("../assets/images/projects/cheviotHills/11.jpg"), 
            require("../assets/images/projects/cheviotHills/12.jpg"), 
        ]
    },
    {
        name: "Teague",
        location: "Rossmoor, California",
        date: "2018",
        shortBody: `Interior furnishing project. 4200 sq ft 2 story house.`,
        longBody: `These clients moved into a space that had been recently remodeled. We just needed to add custom furnishings to complete the space. 
        From custom upholstery to built in mill work, we created a harmonious interior for this young family to raise their kids in  .`,
        photos: [
            require("../assets/images/projects/rossmoor/2.jpg"),
            require("../assets/images/projects/rossmoor/1.jpg"),
            require("../assets/images/projects/rossmoor/3.jpg"), 
            require("../assets/images/projects/rossmoor/4.jpg"),
            require("../assets/images/projects/rossmoor/5.jpg"), 
            require("../assets/images/projects/rossmoor/6.jpg"), 
            require("../assets/images/projects/rossmoor/7.jpg"), 
            require("../assets/images/projects/rossmoor/8.jpg"), 
        ]
    },
    {
        name: "Melville",
        location: "Rolling Hills, California",
        date: "2017-2018",
        shortBody: `Full remodel and addition. 5100 sq ft single story house and ADU (Accessory Dwelling Unit).`,
        longBody: `Challenging low ceilings were the main focus to change in this home. Gorgeous ranch style home and needed some breathing room with the low ceilings. 
        We added a new kitchen area which needed to be opened up to the great room, added square footage to the master ensuite to build a closet and bathroom to die for. 
        The surfaces used in this project are so textural and inviting, anyone would love to live here. Transitional and timeless…. This home offers it all now. `,
        photos: [
            require("../assets/images/projects/rollingHills/6.jpg"), 
            require("../assets/images/projects/rollingHills/1.jpg"),
            require("../assets/images/projects/rollingHills/2.jpg"),
            require("../assets/images/projects/rollingHills/3.jpg"), 
            require("../assets/images/projects/rollingHills/4.jpg"),
            require("../assets/images/projects/rollingHills/5.jpg"), 
            require("../assets/images/projects/rollingHills/7.jpg"), 
            require("../assets/images/projects/rollingHills/8.jpg"), 
            require("../assets/images/projects/rollingHills/9.jpg"), 
            require("../assets/images/projects/rollingHills/10.jpg"), 
            require("../assets/images/projects/rollingHills/11.jpg"), 
            require("../assets/images/projects/rollingHills/12.jpg"), 
            require("../assets/images/projects/rollingHills/13.jpg"),
            require("../assets/images/projects/rollingHills/14.jpg"), 
            require("../assets/images/projects/rollingHills/15.jpg"), 
            require("../assets/images/projects/rollingHills/16.jpg"), 
            require("../assets/images/projects/rollingHills/17.jpg"), 
            require("../assets/images/projects/rollingHills/18.jpg"), 
            require("../assets/images/projects/rollingHills/19.jpg"), 
            require("../assets/images/projects/rollingHills/21.jpg"), 
        ]
    },
    {
        name: "Darr",
        location: "Big Bear, California",
        date: "2017",
        shortBody: ` New custom build 4300 sq ft 2 story house.`,
        longBody: `Location, location, location. Full view of conservation land, this 2 story cabin has all you need to enjoy a home in the mountains. 
            Thoughtfully created floor plan with all the main amenities on the second floor which is the same level as the driveway for easability in  the snowy months. 
            The first floor has the guest rooms and game room for guests to enjoy. 
            The massive rock fireplace and large island kitchen will be a place for many memories to be made. `,
        photos: [
            require("../assets/images/projects/bigBear/1.jpg"),
            require("../assets/images/projects/bigBear/2.jpg"),
            require("../assets/images/projects/bigBear/3.jpg"), 
            require("../assets/images/projects/bigBear/4.jpg")
        ]
    },
    {
        name: "Cusick",
        location: "Lake Sherwood, California",
        date: "2015",
        shortBody: `Full remodel. 5800 sq ft 2 story house.`,
        longBody: `Full remodel of an existing tudor style home. We added all new surfaces and created an exterior kitchen area to take in the golf course views. 
        We focused this project on the clients desire to have a home that offers multiple areas to fit their needs for entertaining. 
        `,
        photos: [
            require("../assets/images/projects/868Stafford/1.jpg"),
            require("../assets/images/projects/868Stafford/2.jpg"),
            require("../assets/images/projects/868Stafford/3.jpg"), 
            require("../assets/images/projects/868Stafford/4.jpg"), 
            require("../assets/images/projects/868Stafford/5.jpg"), 
            require("../assets/images/projects/868Stafford/6.jpg"), 
            require("../assets/images/projects/868Stafford/7.jpg"), 
            require("../assets/images/projects/868Stafford/8.jpg"), 
            require("../assets/images/projects/868Stafford/9.jpg"), 
            require("../assets/images/projects/868Stafford/10.jpg"), 
            require("../assets/images/projects/868Stafford/11.jpg"), 
            require("../assets/images/projects/868Stafford/12.jpg"), 
            require("../assets/images/projects/868Stafford/13.jpg"), 
            require("../assets/images/projects/868Stafford/14.jpg"), 
            require("../assets/images/projects/868Stafford/15.jpg"), 
        ]
    },
    {
        name: "Latigo Shore",
        location: "Malibu, California",
        date: "2014",
        shortBody: `Full remodel and addition. 3000 sq ft 2 story house.`,
        longBody: `Situated on one of the largest ocean front lots in Malibu, we had the opportunity to create the perfect beach house setting. 
        Expansive great room and kitchen area that overlooks the waves rolling in. Another project took down to the studs to re-create a floor plan that needed a refresh to take in all the views. 
        This contemporary home features Neolith counters and rich oak flooring. The master suite overlooks the ocean with multi slide doors to capture that much desired breeze. 
        `,
        photos: [
            require("../assets/images/projects/malibu/10.jpg"), 
            require("../assets/images/projects/malibu/3.jpg"), 
            require("../assets/images/projects/malibu/1.jpg"),
            require("../assets/images/projects/malibu/2.jpg"),
            require("../assets/images/projects/malibu/4.jpg"),
            require("../assets/images/projects/malibu/5.jpg"), 
            require("../assets/images/projects/malibu/6.jpg"), 
            require("../assets/images/projects/malibu/7.jpg"), 
            require("../assets/images/projects/malibu/8.jpg"), 
            require("../assets/images/projects/malibu/9.jpg"), 
            require("../assets/images/projects/malibu/11.jpg"), 
            require("../assets/images/projects/malibu/12.jpg"), 
            require("../assets/images/projects/malibu/13.jpg"),
            require("../assets/images/projects/malibu/14.jpg"), 
            require("../assets/images/projects/malibu/15.jpg"), 
            require("../assets/images/projects/malibu/16.jpg"), 
            require("../assets/images/projects/malibu/17.jpg"), 
            require("../assets/images/projects/malibu/18.jpg"), 
            require("../assets/images/projects/malibu/19.jpg"), 
            require("../assets/images/projects/malibu/21.jpg"), 
            require("../assets/images/projects/malibu/22.jpg"), 
            require("../assets/images/projects/malibu/23.jpg"), 
            require("../assets/images/projects/malibu/24.jpg"), 
        ]
    },
    {
        name: "Mirisch",
        location: "Westlake Village, California",
        date: "2014",
        shortBody: `Full remodel and addition. 4500 sq ft single story house.`,
        longBody: `This traditional home needed a bit more added to it as well as change the interior floor plan to create new spaces that offered our clients a much better floor plan. 
            added onto the kitchen and master en suite to create a better use of space. 
            We transformed this outdated traditional home into a newly created transitional space. 
            Incorporating existing antiques and adding new transitional pieces, we created a fresh interior for these clients to enjoy .`,
        photos: [
            require("../assets/images/projects/mirisch/2.JPG"),
            require("../assets/images/projects/mirisch/1.JPG"), 
            require("../assets/images/projects/mirisch/3.JPG"),
            require("../assets/images/projects/mirisch/4.JPG"),
            require("../assets/images/projects/mirisch/5.JPG"), 
        ]
    },
    {
        name: "Henney",
        location: "Studio City, Nevada",
        date: "2010",
        shortBody: `Full remodel and addition. 4600 sq ft single story house.`,
        longBody: `SIngle story ranch bungalow that was totally original to 1965. Dark, low ceilings and lack of architecture, we added it all to this project. 
        Adding structural beams to raise the living room ceiling, to adding an additional rounded window to match the existing rounded window in the media room/ study… We kept the bungalow architectural integrity but added a fresh spin on it.`,
        photos: [
            require("../assets/images/projects/studioCity/12.jpg"), 
            require("../assets/images/projects/studioCity/6.jpg"), 
            require("../assets/images/projects/studioCity/1.jpg"),
            require("../assets/images/projects/studioCity/2.jpg"),
            require("../assets/images/projects/studioCity/3.jpg"), 
            require("../assets/images/projects/studioCity/4.jpg"),
            require("../assets/images/projects/studioCity/5.jpg"), 
            require("../assets/images/projects/studioCity/7.jpg"), 
            require("../assets/images/projects/studioCity/8.jpg"), 
            require("../assets/images/projects/studioCity/9.jpg"), 
            require("../assets/images/projects/studioCity/10.jpg"), 
            require("../assets/images/projects/studioCity/11.jpg"), 
        ]
    },
    {
        name: "Spaeth",
        location: "Thousand Oaks, California",
        date: "2009",
        shortBody: `Full remodel 5000 sq ft 2 story house.`,
        longBody: `Spanish style 2 story home in need of a new floor plan so the kitchen was more user friendly for this active family. 
            We added a massive island by removing some walls into a small outdated family room. 
            A dining room built to enjoy many family meals. E
            very bathroom has custom tile work. A master ensuite with a steam shower you never want to get out of. `,
        photos: [
            require("../assets/images/projects/thousandOaks/3.JPG"), 
            require("../assets/images/projects/thousandOaks/1.JPG"),
            require("../assets/images/projects/thousandOaks/2.JPG"),
            require("../assets/images/projects/thousandOaks/4.JPG"),
            require("../assets/images/projects/thousandOaks/5.JPG"), 
            require("../assets/images/projects/thousandOaks/6.JPG"), 
            require("../assets/images/projects/thousandOaks/7.JPG"), 
            require("../assets/images/projects/thousandOaks/8.JPG"), 
            require("../assets/images/projects/thousandOaks/9.JPG"), 
            require("../assets/images/projects/thousandOaks/10.JPG"), 
            require("../assets/images/projects/thousandOaks/11.JPG"), 
            require("../assets/images/projects/thousandOaks/12.JPG"), 
            require("../assets/images/projects/thousandOaks/13.JPG"),
            require("../assets/images/projects/thousandOaks/14.JPG"), 
            require("../assets/images/projects/thousandOaks/15.jpg"), 
            require("../assets/images/projects/thousandOaks/16.jpg"), 
        ]
    },
    {
        name: "Weldon",
        location: "Westlake Village, California",
        date: "2009",
        shortBody: `Full remodel and addition 3500 sq ft 2 story house.`,
        longBody: `This home needed a new interior floor plan so the master suite could be on the first floor. We closed off an outdated interior atrium to achieve this. 
            Added a massive bathroom for the master bedroom. Every surface was remodeled to freshen up this outdated transitional home. 
            The second story houses the guest rooms now, making this a perfect home for guests to enjoy.`,
        photos: [
            require("../assets/images/projects/westlakeVillage/1.jpg"),
            require("../assets/images/projects/westlakeVillage/2.jpg"),
            require("../assets/images/projects/westlakeVillage/3.jpg"), 
            require("../assets/images/projects/westlakeVillage/4.jpg"),
            require("../assets/images/projects/westlakeVillage/5.jpg"), 
            require("../assets/images/projects/westlakeVillage/6.jpg"), 
            require("../assets/images/projects/westlakeVillage/7.jpg"), 
            require("../assets/images/projects/westlakeVillage/8.jpg"), 
            require("../assets/images/projects/westlakeVillage/9.jpg"), 
            require("../assets/images/projects/westlakeVillage/10.jpg"), 
        ]
    }
]