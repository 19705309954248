import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = { 
          year: new Date().getFullYear(),
          deviceWidth: 0,
          deviceHeight: 0
        };
    }
      
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
      
    updateWindowDimensions = () => {
        this.setState({ deviceWidth: window.innerWidth, deviceHeight: window.innerHeight });
    }

    render() {
        return (
            <footer>
                <div className={this.state.deviceWidth >= 900 ? "f-container" : "hide"}>
                    <div className="left">
                        &nbsp;&nbsp; &nbsp;&nbsp;<Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms">Terms &amp; Conditions</Link> | <Link to="/credits">Credits</Link>&nbsp;&nbsp;
                    </div>

                    <div className="center">
                        <div className="center-text">
                            &nbsp;&nbsp;
                            <a href="https://www.instagram.com/interiormotivesla/" target="_blank" rel="noopener noreferrer" className="black">
                                <i className="fab fa-instagram"/>
                            </a>
                            &nbsp;&nbsp;
                            <a href="https://www.houzz.com/professionals/architects-and-building-designers/interior-motives-la-pfvwus-pf~1417865733?" target="_blank" rel="noopener noreferrer" className="black">
                                <i className="fab fa-houzz" />
                            </a>
                        </div>
                        &copy;
                        {' '}
                        {this.state.year}
                        {' '}
                        Interior Motives LA <br/>by Michele Townley (CID #60030)
                    </div>

                    <div className="right">
                        <a href="https://minute.tech" target="_blank" rel="noopener noreferrer">Built by Minute.tech</a> 
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div className={this.state.deviceWidth < 900 ? "f-container" : "hide"}>
                    <div>
                        &nbsp;&nbsp;
                        <a href="https://www.instagram.com/interiormotivesla/" target="_blank" rel="noopener noreferrer" className="black">
                            <i className="fab fa-instagram"/>
                        </a>
                        &nbsp;&nbsp;
                        <a href="https://www.houzz.com/professionals/architects-and-building-designers/interior-motives-la-pfvwus-pf~1417865733?" target="_blank" rel="noopener noreferrer" className="black">
                            <i className="fab fa-houzz" />
                        </a>
                    </div>
                    <div>
                        &copy;
                        {' '}
                        {this.state.year}
                        {' '}
                        Interior Motives LA<br/> by Michele Townley (CID #60030)
                    </div>
                    <div>
                        <a href="https://minute.tech" target="_blank" rel="noopener noreferrer">Built by Minute.tech</a> 
                    </div>
                    <div className="sm-padding-b">
                        <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms">Terms &amp; Conditions</Link> | <Link to="/credits">Credits</Link> 
                    </div>
                </div>
            </footer>
        )
    }
}
