import React, { Component } from 'react'
import { NavLink, Link, withRouter } from "react-router-dom";
import { HashLink as HashNavLink } from 'react-router-hash-link';
import logo from "../../assets/images/logos/logo-transparent.png";
import "../../assets/css/Header.scss";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = { 
          deviceWidth: 0,
          deviceHeight: 0
        };
    }
      
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
      
    updateWindowDimensions = () => {
        this.setState({ deviceWidth: window.innerWidth, deviceHeight: window.innerHeight });
    }

    render() {
        return (
            <header>
                <div className={this.state.deviceWidth >= 900 ? "" : "hide"}>
                    <nav className="nav-container">
                        <div className="nav-links">
                            <NavLink 
                                exact
                                to="/projects" 
                                className="nav-link" 
                                activeClassName="nav-select">
                                Projects
                            </NavLink> 
                            <NavLink 
                                exact
                                to="/about" 
                                className="nav-link" 
                                activeClassName="nav-select">
                                About
                            </NavLink>
                            <Link to="/" className="">
                                <img 
                                    src={logo} 
                                    alt={"interior motives logo"} 
                                    className="nav-logo" 
                                />
                            </Link>
                            <NavLink 
                                exact
                                to="/faq" 
                                className="nav-link" 
                                activeClassName="nav-select">
                                FAQ
                            </NavLink>
                            <HashNavLink 
                                exact="true"
                                to="/#Contact" 
                                className="nav-link">
                                Contact
                            </HashNavLink>
                        </div>
                    </nav>
                </div>
                <div className={this.state.deviceWidth < 900 ? "" : "hide"}>
                    <Link to="/" className="">
                        <img 
                            src={logo} 
                            alt={"interior motives logo"} 
                            className="mobile-nav-logo" 
                        />
                    </Link>
                    <input type="checkbox" id="hamburger" />
                    <label htmlFor="hamburger" className="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>

                    <nav className="side-nav">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/faq">FAQ</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                </div>
            </header>
        )
    }
}

export default withRouter(Header);