import React, { Component } from 'react'
import { Route, Switch, withRouter } from "react-router-dom";

import { Page404 } from "./components/misc/Page404";

// Pages
import Home from './components/pages/Home'
import About from './components/pages/About';
import Credits from './components/pages/Credits';
import ErrorBoundary from './components/misc/ErrorBoundary';
import Projects from './components/pages/projects/Projects';
import FAQ from './components/pages/FAQ';
import ProjectPage from './components/pages/projects/ProjectPage';
import { projects } from './utils/projects';
import Header from './components/misc/Header';
import Footer from './components/misc/Footer';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Terms from './components/pages/Terms';

class Routes extends Component {
    render() {
        return (
            <>
            {this.props.location.pathname !== "/" && (<Header />)}

            <Switch>  
                <Route 
                    exact 
                    path="/" 
                    component={() =>
                        <ErrorBoundary>
                            <Home />
                        </ErrorBoundary>
                    } 
                />
                <Route 
                    exact 
                    path="/about" 
                    component={() =>
                        <ErrorBoundary>
                            <About />
                        </ErrorBoundary>
                    } 
                />
                <Route 
                    exact 
                    path="/projects" 
                    component={() =>
                        <ErrorBoundary>
                            <Projects />
                        </ErrorBoundary>
                    } 
                />
                <Route 
                    exact 
                    path="/faq" 
                    component={() =>
                        <ErrorBoundary>
                            <FAQ />
                        </ErrorBoundary>
                    } 
                />
                <Route 
                    exact 
                    path="/privacy-policy" 
                    component={() =>
                        <ErrorBoundary>
                            <PrivacyPolicy />
                        </ErrorBoundary>
                    } 
                />
                <Route 
                    exact 
                    path="/terms" 
                    component={() =>
                        <ErrorBoundary>
                            <Terms />
                        </ErrorBoundary>
                    } 
                />
                <Route 
                    exact 
                    path="/credits" 
                    component={() =>
                        <ErrorBoundary>
                            <Credits />
                        </ErrorBoundary>
                    } 
                />
                {  
                    projects.map((project, i) => {
                        return (
                            <Route 
                                key={i} 
                                exact path={`/projects/${project.name.split(" ").join("-").toLowerCase()}`} 
                                component={() => 
                                    <ProjectPage
                                        project={project} 
                                    />
                                    } 
                                />
                        )
                    })  
                } 
                <Route component={() => <Page404 />} />
            </Switch>
            <Footer />
            </>

        )
    }
}

export default withRouter(Routes);