import React, { Component } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'

export default class ProjectPreview extends Component {
    render() {
        return (
            <Link to={`/projects/${this.props.project.name.split(" ").join("-").toLowerCase()}`}>
                <Row middle="xs" className={`project-preview ${this.props.index % 2 === 0 ? "border-left border-bottom" : "border-right border-bottom"}`}>
                    <Col xs={12} sm={this.props.index % 2 === 0 ? 4 : 8}>
                        {this.props.index % 2 === 0 && (
                            <ProjectImageSection project={this.props.project} />
                        )}
                        {this.props.index % 2 !== 0 && (
                            <ProjectTitleSection index={this.props.index} project={this.props.project} />
                        )}
                    </Col>
                    <Col xs={12} sm={this.props.index % 2 === 0 ? 8 : 4}>
                        {this.props.index % 2 !== 0 && (
                            <ProjectImageSection project={this.props.project} />
                        )}
                        {this.props.index % 2 === 0 && (
                            <ProjectTitleSection index={this.props.index} project={this.props.project} />
                        )}
                    </Col>
                </Row>
            </Link>
            
            
        )
    }
}

const ProjectTitleSection = (props) => {
    return (
        <>
            <h2 className={`name ${props.index % 2 === 0 ? "" : "text-right"}`}>{props.project.name}</h2>
            
            <p className={`short-body ${props.index % 2 === 0 ? "" : "text-right"}`}>{props.project.shortBody}</p>
            <p className={`location ${props.index % 2 === 0 ? "" : "text-right"}`}>{props.project.location}</p>
            <p className={`date ${props.index % 2 === 0 ? "" : "text-right"}`}>{props.project.date}</p>
        </>
    )
}

const ProjectImageSection = (props) => {
    return <img src={props.project.photos[0]} alt={`project ${props.project.name}`} />
}

