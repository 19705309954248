import React, { Component } from 'react'
import PhotoGallery from '../../misc/PhotoGallery'

export default class ProjectPage extends Component {
    render() {
        return (
            <div className="wrapper">
                <div className="project-page">
                    <h1>{this.props.project.name}</h1>
                    <div className="location">{this.props.project.location}</div> 
                    <div className="date">{this.props.project.date}</div>
                    <div className="horiz-rule-teal-green"/>
                    <p className="body">{this.props.project.longBody}</p>
                    
                    <div className="horiz-rule-teal-green"/>
                    <br/>
                    {/* TODO: wait to load all product images? or reduce their sizE? */}
                    <PhotoGallery photos={this.props.project.photos} projectName={this.props.project.name} />
                </div>
                
                
            </div>
        )
    }
}
