// TODO: You should probably be using constants! Try to think of common stuff that we want to ensure is spelled right (like the state of a user or status of a ticket)

export const testimonials = [
    {
        name: "Steve Greenberg ",
        message: `<p>I am the owner of My Delivery Company and have worked with Michele Townley of Interior Motives for about 7 years. Without a doubt, Michele is the most competent and easiest person to work with. Her attention to detail and specifics is like no other. We have delivered and installed numerous jobs for Michele in the past and have noticed that she has a special way with her clients. Of all the designers I have worked with , Michele understands her individual clients the best. She represents her clients with the utmost professionalism and stands behind them in every aspect of the job. Each job is just as important to her, since they all are a reflection of her. Her attitude, integrity and time she spends on each project make her one of a kind! I always know that when Michele needs my services, her directions are clear and precise making for an easy installation. Interior Motives is at the top of my list and should be on yours!</p>`
    },
    {
        name: "Leesa Mayer@ Kravet -Los Angeles PDC",
        message: `<p>I have worked with Michele for the last five years & only know her to be a consumate professional. She puts her client's needs first & foremost & works tirelessly to make their design dreams come true.</p>`
    },
    {
        name: "M2 Multimedia Inc.",
        message: `<p>Michele has always been one of the best trades for us to work with. She is very professional both with us and the client, you would be very lucky to work with her!</p>`
    },
    {
        name: "Helene",
        message: `<p>Michelle has turned our house of the sixties into a beautiful home of today. Her attention to detail and her insight allowed us to create a home that is as comfortable as it is efficient. Michele was always available to give us answers to our questions or concerns. Working with her has been a pleasure.</p>`
    },
    {
        name: "Mary R",
        message: `<p>I have hired Michele Townley to plan and decorate three houses for me. She did a terrific job on all three and I would hire her again. She is very professional and gets the jobs done on time. Her insight into interior design is excellent. She is a pleasure to work with.</p>`
    },
    {
        name: "Gwen",
        message: `<p>Michele helped my husband and I update and decorate our family room/kitchen area. She took time to get to know us and understand our taste. Her eye for color and creativity was exactly what we needed. Michele redesigned our fireplace to accommodate a large flatscreen television above. Everyone now comments how the television looks like it belongs there. The media specialist, cabinetry and tile contractors that Michele recommended were professional, meticulous, and very personal. They were a true reflection of Michele. I continue to be in touch with Michele and consult with her for decorating ideas. I highly recommend her for any interior design job, big or small.</p>`
    },
    {
        name: "Cadi S",
        message: `<p>Michele Townley and I have worked together on numerous occasions. Her professionalism and design expertise are enlightening. Her exquisite taste and her ability to see the big picture helped bring my own style into a functional and beautiful living space. I highly recommend her work and will use her many times in the future.</p>`
    },
    {
        name: "Angie K",
        message: `
        <p>
            It is my pleasure to write this recommendation for Michele Townley as an interior designer. I had moved and I had many concerns how to fit all of my furniture and belongings into a much smaller home. I could see there were endless possibilities, but at the time I was unable to afford the extensive remodel that would be needed to suit my needs.
        </p>
        <p>
            The day came when I was able to engage a young, upcoming designer. I asked Michele to come over to see my extensive Christmas decorations so she could understand how important a compatible color scheme would be for the future decor. I also stressed my attachment to certain pieces of furniture I wanted to continue to use.
        </p>
        <p>
            As she walked through my house I watched as she evaluated all of the rooms. We talked about my lifestyle, color and some of my ideas. I wanted a complete computer, craft and sewing room, a laundry room with a sink. I thought it would be a good idea to reverse the family room and kitchen. I needed linen cabinets and a better, more functional master bed and bath. The most important thing Michele did was to listen to me and take notes. One of the last things she stressed on that first meeting was, “It doesn’t matter if I like it or if it isn’t my personal taste. It is most important if you like it. You have to live with it everyday and I don’t. I want to help you incorporate your style and color so it works in your home the best possible way.” I was thrilled she understood my vision, and astonished by her expertise and ability to lay it all out.
        </p>
        <p>
            The remodel began with some of my furniture being moved to storage, some to be re-upholstered and some items sent for extensive cleaning or restoration. I moved out of the premises and the house was gutted. The process took approximately six months.
        </p>
        <p>
            When all was said and done I had a downstairs laundry room complete with large sink and cupboards. My kitchen is now where the family room had been and the kitchen sink view overlooks the lake and a redesigned back patio. The newly created breakfast nook has the same outstanding view. The island in the kitchen has a huge work surface with a prep sink. There’s a warming oven, huge double ovens, a five burner cook top and built-in microwave oven. My kitchen almost doubled in size and efficiency. My computer, craft and sewing room (also known as the “women’s garage”) is lined with shelves, baskets, cupboards and counter space in addition to the added attraction of a serviceable island for cutting fabric. I have linen cabinets in all of my bathrooms as well as a huge linen/storage cabinet in the upstairs hallway. My master bath and bedroom is truly a suite. The bathroom features a sunken tub, huge shower with sitting space, opposing sinks and vanity. There’s a doorway from the master bath into a spacious walk-in closet. The master bedroom features a sitting area over looking the lake and a built-in large flat screen TV.
        </p>
        <p>
            My house is colorful and cheerful. All of my old furniture was incorporated beautifully into the new designs. The window treatments are exciting and well done with attention to detail in every stitch. Michele uses the most talented and expert craftsmen for faux painting, tile installation and cabinetry. My house is livable, comfortable and a pleasure show off thanks to Michele.
        </p>
        `
    },
    {
        name: "Wendy W",
        message: `<p>
            Michele is simply a joy to work with. She is bright, capable, energetic and fun. I went into the complete remodel of my house with tremendous trepidation. I am a full-time working mother of three and do not like to shop. That is not a good start for a project like this. Michele understood my taste, brought her own vision and made the entire process simply a delight. So much so, we are working on another project together and I cannot imagine doing it with anyone other than Michele.
        </p>`
    },
]

