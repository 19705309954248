import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import { testimonials } from '../../utils/constants';
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation"


// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

export default class About extends Component {

    render() {
        return (
            <div className="wrapper">
                <Helmet>
                    <title>About | Interior Motives LA</title>
                </Helmet>
                <h1>About</h1>
                <p>
                    Design has always been a passion for me. Being the daughter of an engineer, I was fortunate in learning the principles of design. 
                    In 2000, I graduated from LTU University and became a Certified Interior Designer as well as certification of the California Building Codes &amp; Regulations. 
                    My dreams became my reality; I started my own design business with many wonderful projects, from the simple to the complex, from one room
                    makeovers to complete remodels.The projects are all inclusive: structural modifications, specifications of building materials, fixtures, appliances, floor coverings, 
                    fabrics, furniture and the finishing touches or “fluff” as some. change
                </p>
                {/* TODO: create quote styling for this? */}
                <p><i>"My work is to discover your world and give my heart to create a space that inspires you"</i> -Michele Townley</p> 
                <img src={require("../../assets/images/misc/headshot.jpg")} className="headshot" alt="michelle headshot" />
                <p>
                    Interior Motives is a complete design firm that will meet all your expectations in creating your vision.
                </p>
                <p>
                    What sets Interior Motives apart from other residential design firms is that I listen to the needs and desires of my clients. 
                    Utilizing education and experience, I will create an environment that inspires you. With my attention to scale, texture, color, 
                    and detail your home will become a space that you love to live in plus being admired by all who enter.  I value my client's needs 
                    and as a “home body” myself, I understand what it means to have a house that is “your personal haven”.
                </p>
                <p>
                    I always try to incorporate the yin and yang of a space. There is much to be said for listening versus talking and telling clients what is needed. 
                    My inspiration stems from you— tell me all about you and your lifestyle— it is from this I learn what really drives you, which fuels my design fire 
                    to create your dream. Our consultation meeting is for my listening and understanding what you desire. After hearing what you want, I will begin to 
                    envision what your home will need for your perfect environment. We will then discuss various design concepts.
                </p>
                <p>
​                   At Interior Motives, we pride ourselves in staying on budget, staying on task and meeting deadlines. 
                    I am grateful and proud to say that the vendors which are referred are among the best in their field. It is the work they complete for my 
                    clients that builds the foundation of Interior Motives, a design firm built on integrity.
                </p>

                <h2><a id="Testimonials" className="anchor" href="/#">Testimonials</a>Testimonials</h2>
                <Swiper 
                    spaceBetween={30} 
                    centeredSlides={true} 
                    autoHeight={true}
                    autoplay={{
                        "delay": 4000,
                        "disableOnInteraction": false,
                        "pauseOnMouseEnter": true
                    }} 
                    pagination={{
                        "clickable": true
                    }} 
                    navigation={false} 
                >
                    { testimonials.map((person, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <figure className="quote" key={i}>
                                    <blockquote>
                                        <div className="message" dangerouslySetInnerHTML={{__html: person.message}}/>
                                    </blockquote>
                                    <figcaption>
                                        &mdash; <cite>{person.name}</cite>  
                                    </figcaption>
                                </figure>
                            </SwiperSlide>
                        )}
                    )}
                </Swiper>
                  
            </div>
        )
    }
}
