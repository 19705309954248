import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import ContactForm from '../misc/ContactForm';
import Header from '../misc/Header';
// \assets\images\projects\bubman\5.jpg
class Home extends Component {
    render() {
        return (
            <>
            <Helmet>
                <title>Home | Interior Motives LA</title>
            </Helmet>
                
                <div className="hero-container">
                    <div className="hero-nav">
                        <Header  />
                    </div>
                    
                    <div className="hero-text">
                        <h1>Interior Design by Michele Townley</h1>
                        <p>Designing throughout the United States</p>
                        <Link to="/projects">
                            <button className="md-wood-brown-btn sm-margin-t">
                                View projects
                            </button>
                        </Link>
                    </div>
                    
                </div>
                <div className="parallax"></div>
            
            {/* <svg 
                    version="2" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    x="0px" 
                    y="0px"
                    viewBox="0 0 1000 82" 
                    xmlSpace="preserve"
                    style={{marginBottom: "-10px", position: "relative", zIndex: "2"}}
                >
               <g>
                        <g>
                            <path 
                                className="st0" 
                                d="M0.5,104.5c-0.03-10.72-0.46-13.95-1-27c-0.78-18.76-1.17-28.13,0-30c8.44-13.43,34.31-4,73-14.68
                                c34.04-9.39,34.13-23.39,56.61-23.16c26.12,0.27,45.63,23.27,69.21,34.52c31.75,15.14,70.74,8.57,102.11,24.49
                                c15.93,8.08,29.32,21.71,46.75,25.65c21.01,4.75,42.53-5.91,59.36-19.37c16.84-13.45,31.33-30.08,50.11-40.65
                                c7.44-4.19,15.78-7.41,24.32-6.93c21.39,1.2,36.88,24.58,58.3,24.95c16.34,0.29,29.91-12.99,46.02-15.73
                                c23.28-3.97,43.72,14.26,65.48,23.44c28.79,12.14,63.95,7.64,88.75-11.36c11.55-8.85,20.82-20.44,32.55-29.06
                                c11.72-8.62,27.47-14.06,40.85-8.33c23.73,10.18,24.68,46.79,47.13,59.54c15.13,8.59,34.83-2.98,72.35-22.32
                                c43.96-22.65,63.35-42.42,72.1-36c4.02,2.95,1.27,8.79-1,34c-1.93,21.5-3.68,41-2,63c1.61,21.08,5.74,36.08-1,40
                                c-2.03,1.18-3.65,0.54-11,0c0,0,0,0-23,0c-34.33,0-68.67,0-103,0c-102.67,0-205.33,0-308,0c-64.67,0-129.33,0-194,0
                                c-37.33,0-74.67,0-112,0c-25.33,0-50.67,0-76,0c-36,0-72.01-1-108,0c-14.33,0.4-28.68-0.62-43,0c-1.48,0.06-4.66,0.04-11,0
                                c-8.91-0.06-9.98,0.01-11-1c-2.25-2.24-0.33-5.59,0-18c0,0,0,0,0-7C0.5,110.84,0.5,104.51,0.5,104.5z"
                            />
                            <path 
                                className="st1"
                                d="M-0.5,47.5c8.44-13.43,34.31-4,73-14.68c34.04-9.39,34.13-23.39,56.61-23.16
                                c26.12,0.27,45.63,23.27,69.21,34.52c31.75,15.14,70.74,8.57,102.11,24.49c15.93,8.08,29.32,21.71,46.75,25.65
                                c21.01,4.75,42.53-5.91,59.36-19.37c16.84-13.45,31.33-30.08,50.11-40.65c7.44-4.19,15.78-7.41,24.32-6.93
                                c21.39,1.2,36.88,24.58,58.3,24.95c16.34,0.29,29.91-12.99,46.02-15.73c23.28-3.97,43.72,14.26,65.48,23.44
                                c28.79,12.14,63.95,7.64,88.75-11.36c11.55-8.85,20.82-20.44,32.55-29.06c11.72-8.62,27.47-14.06,40.85-8.33
                                c23.73,10.18,24.68,46.79,47.13,59.54c15.13,8.59,34.83-2.98,72.35-22.32c43.96-22.65,63.35-42.42,72.1-36
                                c4.02,2.95,1.27,8.79-1,34c-1.93,21.5-3.68,41-2,63c1.61,21.08,5.74,36.08-1,40c-2.03,1.18-3.65,0.54-11,0c0,0,0,0-23,0
                                c-34.33,0-68.67,0-103,0c-102.67,0-205.33,0-308,0c-64.67,0-129.33,0-194,0c-37.33,0-74.67,0-112,0c-25.33,0-50.67,0-76,0
                                c-36,0-72.01-1-108,0c-14.33,0.4-28.68-0.62-43,0c-1.48,0.06-4.66,0.04-11,0c-8.91-0.06-9.98,0.01-11-1c-2.25-2.24-0.33-5.59,0-18
                                c0,0,0,0,0-7c0-12.66,0-18.99,0-19c-0.03-10.72-0.46-13.95-1-27C-1.28,58.74-1.67,49.37-0.5,47.5z"
                            />
                        </g>
                    </g>
                </svg> */}
            <div className="full-width bg-teal-green border-t-b-brown">
                <div className="wrapper">
                    <h1 className="no-margin">Services</h1>
                    <p>
                        Interior Motives is a full-service interior design and project management firm. 
                        Whether you are interested in a simple design consultation or are considering a major residential renovation, 
                        we can help. We believe that our clients deserve more than big-box design, and will work with you to create a 
                        personalized living environment. Our clients keep coming back to us because we are passionate about giving individualized 
                        service and invest ourselves fully in every project we take on, keeping the client's needs our first and foremost priority. 
                        We are always looking for new design challenges, no matter the size or budget. Contact us to learn more.
                    </p>
                    
                    <Grid fluid className="lg-container">
                        <Row center="xs" between="xs" >
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/blueprint.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Space Planning <br/>(floor plans and elevations)</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/rendering.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Rendered Presentation Boards</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/estimate.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Finish Selections, Estimates, Budget, and Schedules</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/cabinet.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Custom Cabinetry Design</h4>
                            </Col>
                        </Row>
                        <Row center="xs" between="xs" style={{margin:"35px 0px"}}>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/kitchen.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Kitchen Design</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/bathroom.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Bathroom Design</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/lighting.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Lighting Selection &amp; Electrical Plans</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/idea.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Project Management</h4>
                            </Col>
                        </Row>
                        <Row center="xs" between="xs">
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/furnitures.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Furniture &amp; Accessory Selection</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/window.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Custom Window Treatments</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/stage.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Real Estate Staging</h4>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={2}>
                                <img src={require("../../assets/images/icons/color-palette.png")} alt="icon" className='small' style={{ margin: "15px 0" }} />
                                <h4 className="no-margin">Color Consultations</h4>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
            <div className="wrapper">
                <h1><a id="Contact" className="anchor" href="/#">Contact</a>Contact</h1>
                <ContactForm />
            </div>
            </>
        )
    }
}


export default withRouter(Home);
